.App {
  width: 100wh;
  height: 100vh;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in.show {
  opacity: 1;
}

.text-gray500 {
  color: #6c757d;
}

.input-group-text {
  background-color: transparent !important;
}
